<template>
    <!--
        <h3 style="margin-left:5%; margin-top:15px; ">Mes medias :</h3>
    -->
    <SearchBar style="margin: 20px 5% 0 5%; width: 90%;" @search="searchGif"/>
    <div class="poweredByGiphy_container center">
        <img src='../../../../assets/poweredByGiphy.png' class="poweredByGiphy"/>
    </div>
    <div class="card_gallery" library="giphy" ref="galery" style="height:calc(100% - 88px)">
        <div class="gallery_column" v-for="medias in columns">
                <div class="choice_card" v-for="media in medias" :key="media._id" @click.stop="selectMedia(media)">
                    <img v-if="media.media_thumbnail" :src="media.media_thumbnail"/>
            </div>
        </div>
    </div>
    <div class="loadingMore" v-if="fetchingGifs == true"><span class="loading_spinner" style="--color : var(--color2)" ></span></div>

</template>


<script setup>
//import upload from "./upload.vue"
import { defineAsyncComponent, defineProps, defineEmits, ref, reactive, onMounted, computed, onBeforeUnmount, inject } from 'vue';

import { makeColumns } from "@/components/Composables/columns.js";

const SearchBar = defineAsyncComponent(() => import("../../../Object/searchBar/SearchBar.vue"));

const emit = defineEmits(["mediaSelected"]);

const medias = ref([]);
const galery = ref();
const fetchingGifs = ref(false);

const columns = computed(()=>{
    return makeColumns(medias.value)
})

import { GiphyFetch } from '@giphy/js-fetch-api';

const gf = new GiphyFetch('zyWJHZvHOk18rrqRt8couQc3SpanlPas');

var fetchParams = {
    offset : 0,
    limit: 20,
    trending : true,
    searchQuery : null
}


onMounted(()=>{
    fetchGifs();
    galery.value.addEventListener("scroll", onScroll);

});

var lastScrollPos = 0;

function onScroll(){
    
    const scrollDown = (lastScrollPos < galery.value.scrollTop);
    lastScrollPos = galery.value.scrollTop;

    if (scrollDown){
        if (galery.value.offsetHeight >= (galery.value.scrollHeight - galery.value.scrollTop - 20)) {
            if (fetchingGifs.value == false){
                fetchGifs();
            }
        }
    }

};

function fetchGifs(){
    fetchingGifs.value = true;
    if (fetchParams.trending == true){
        var fetchPromise = gf.trending({ offset : fetchParams.offset, limit: fetchParams.limit })
    } else {
        var fetchPromise = gf.search(fetchParams.searchQuery, {limit: fetchParams.limit, offset: fetchParams.offset})
    }

    fetchPromise.then(res =>{
        fetchingGifs.value = false
        fetchParams.offset += fetchParams.limit;
        medias.value.push(...res.data.map(gif =>{
                gif.media_thumbnail = gif.images.preview_gif.url;
                gif._id = gif.id;
                return gif;
            })
        )
    })
}

function searchGif(searchQuery){
    fetchParams.offset = 0;
    fetchParams.limit = 20;
    fetchParams.trending = false;
    fetchParams.searchQuery = searchQuery;
    medias.value = [];
    fetchGifs();
}

function selectMedia(media){
    const set = {
        type : "video",
        origin : "giphy",
        static_url : media.images.original_mp4.mp4,
        media_height : media.images.original_mp4.height,
        media_width: media.images.original_mp4.width,
        media_duration : Infinity,
        media_id: null
    }

    emit('mediaSelected', set)
}

</script>

<style scopped>
    .poweredByGiphy_container{
        width: 100%;
        display: flex;
        justify-content: flex-end;
    }
    .poweredByGiphy_container > .poweredByGiphy{
        height: 14px;
        margin-top: 9px;
        margin-right: 4%;
    }
</style>